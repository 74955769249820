<template>
<div>
  <div class="flex-start">
    <div style="margin-right:10px;width:120px">
      <el-select
        v-model="year"
        @change="handleChangeYear"
      >
        <el-option
          v-for="item in yearDropDownList"
          :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
     <div  style="margin-right:20px;width:120px">
       <el-select
        v-model="month"
        @change="handleChangeMonth"
      >
        <el-option
          v-for="item in monthDropDownList"
          :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
     </div>
     <div  style="margin-right:20px"> <p class="sub-title-text">No. of Orders: {{totalOrdersInMomth}}</p></div>
     <div class="flex-column" v-if="totalDeliveredInMomth">
      <strong class="sub-title-text"> Total Delivered:</strong>
      <p class="text-left-12" v-for="item in totalDeliveredInMomth" :key="item.id" >{{item.displayName}}: {{item.totalQty}} MT</p>
     </div>
  </div>
  <el-table
      :data="childTableData"
      v-loading="childTableLoading"
      :cell-style="CELL_STYLE"
      :header-cell-style="{  alignSelf: 'center', textAlign: 'center', fontSize: '12px'
}"
    >
      <el-table-column label="Status" min-width="200px">
        <template v-slot="scope">
          <p>
            <em
              class="iconfont icon-yuandianzhong"
              :style="tableOrderTypeStyle(scope.row.sellerSysOrganizationId === myCompanyId ? scope.row.sellerOrderStatus : scope.row.buyerOrderStatus)"
            >
            </em>
            {{scope.row.sellerSysOrganizationId === myCompanyId ? capitalizeFirstLetter(scope.row.sellerOrderStatus, '_') : capitalizeFirstLetter(scope.row.buyerOrderStatus, '_')}}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="Ref No." min-width="150px" show-overflow-tooltip>
        <template v-slot="scope">
          <p  v-if="(scope.row.sellerSysOrganizationId === myCompanyId && scope.row.sellerReferenceNo) || (scope.row.buyerSysOrganizationId === myCompanyId && scope.row.buyerReferenceNo)">
            {{scope.row.sellerSysOrganizationId === myCompanyId ? scope.row.sellerReferenceNo : scope.row.buyerReferenceNo }}
          </p>
          <p v-else > - </p>
        </template>
      </el-table-column>
      <el-table-column label="Terminal" min-width="150px" show-overflow-tooltip v-if="termContractData.termContractType === TERM_CONTRACT_TYPE.EX_WHARF">
        <template v-slot="scope">
          <p>
            {{scope.row.vesselName || '-'}}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="Vessel" min-width="150px" show-overflow-tooltip v-else>
        <template v-slot="scope">
          <p>
            {{scope.row.vesselName || '-'}}
          </p>
        </template>
      </el-table-column>
      <el-table-column  label="ETA Range (From-To)" width="200px">
        <template v-slot="scope">
          <p>
            {{scope.row.etaDiaplayName || '-'}}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="Product/Grade/Specs" min-width="300px" show-overflow-tooltip>
        <template v-slot="scope">
          <p v-for="item in scope.row.products" :key="item.id" >
            <el-tag type="info">{{`${item.displayName}`}}</el-tag>
          </p>
        </template>
      </el-table-column>
      <el-table-column label="Min - Max Qty" min-width="100px" show-overflow-tooltip>
        <template v-slot="scope">
          <div v-for="item in scope.row.products" :key="item.id">
            <p v-if="item.minimumQuantity">{{`${item.minimumQuantity} ${item.unitMetric}`}} - {{`${item.maximumQuantity} ${item.unitMetric}`}}</p>
            <p v-else>-</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Final Qty" min-width="100px" show-overflow-tooltip>
        <template v-slot="scope">
          <div v-for="item in scope.row.products" :key="item.id">
            <p v-if="item.finalQuantity">{{`${item.finalQuantity} ${item.unitMetric}`}}</p>
            <p v-else>-</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Loading Qty" min-width="100px" show-overflow-tooltip v-if="termContractData.termContractType === TERM_CONTRACT_TYPE.EX_WHARF">
        <template v-slot="scope">
          <div v-for="item in scope.row.products" :key="item.id">
            <p v-if="item.verifiedQuantity">{{`${item.verifiedQuantity} ${item.unitMetric}`}}</p>
            <p v-else>-</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Delivered Qty" min-width="100px" show-overflow-tooltip v-else>
        <template v-slot="scope">
          <div v-for="item in scope.row.products" :key="item.id">
            <p v-if="item.verifiedQuantity">{{`${item.verifiedQuantity} ${item.unitMetric}`}}</p>
            <p v-else>-</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="BDN" min-width="150px" show-overflow-tooltip v-if="termContractData.termContractType !== TERM_CONTRACT_TYPE.EX_WHARF">
        <template v-slot="scope">
          <div v-for="item in scope.row.products" :key="item.id">
             <p v-if="item.physicalSupplierName">{{item.physicalSupplierName}}</p>
            <p v-else>-</p>
          </div>
        </template>
      </el-table-column>
    </el-table>
</div>
</template>
<script>
import { ORDER_TYPE, ORDER_STATUS, TERM_CONTRACT_TYPE, CELL_STYLE, HEADER_CELL_STYLE } from '@/constants.js'
import { capitalizeFirstLetter } from '@/utils/index.js'
export default {
  name: 'ChildTable',
  props: {
    termContractData: Object
  },
  data () {
    const MONTH_OPTION = [
      { value: 1, label: 'January' },
      { value: 2, label: 'February' },
      { value: 3, label: 'March' },
      { value: 4, label: 'April' },
      { value: 5, label: 'May' },
      { value: 6, label: 'June' },
      { value: 7, label: 'July' },
      { value: 8, label: 'August' },
      { value: 9, label: 'September' },
      { value: 10, label: 'October' },
      { value: 11, label: 'November' },
      { value: 12, label: 'December' }
    ]
    return {
      ORDER_TYPE,
      ORDER_STATUS,
      TERM_CONTRACT_TYPE,
      MONTH_OPTION,
      HEADER_CELL_STYLE,
      CELL_STYLE,
      myCompanyId: this.$store.state.currentCompany.id,
      childTableData: null,
      childTableLoading: false,
      contractDates: {
        yearOptions: [],
        monthOptios: [],
        startYear: null,
        endYear: null,
        startMonth: null,
        endMonth: null,
        endDay: null,
        startDay: null
      },
      year: new Date().getUTCFullYear(),
      month: new Date().getUTCMonth() + 1,
      monthDropDownList: this.$_.cloneDeep(MONTH_OPTION),
      yearDropDownList: [],
      totalOrdersNum: 0,
      totalOrdersInMomth: 0
    }
  },
  created () {
    this.getContractDates()
  },
  computed: {
    totalDeliveredInMomth () {
      if (this?.childTableData?.length > 0) {
        const productArr = []
        this.childTableData.forEach(item => productArr.push(item.products))
        const productCat = this.$_.uniqWith(productArr.flat().map(p => {
          return { name: p.sysProductName, spec: p.sysProductSpecificationName, displayName: p.displayName }
        }), this.$_.isEqual)
        productCat.forEach((pct, idx) => {
          pct.id = `${pct.name.slice(0, 4)}${pct.spec.slice(-4)}${idx}`
          const pArr = productArr.flat().filter(p => p.sysProductName === pct.name && p.sysProductSpecificationName === pct.spec)
          pct.totalQty = pArr.reduce((sum, e) => sum + Number(e.verifiedQuantity || 0), 0)
        })
        return productCat
      } else {
        return null
      }
    }
  },
  methods: {
    capitalizeFirstLetter,
    getOrders (id) {
      const startDate = (this.$moment(`${this.year}-${this.month}`).startOf('month')).toISOString()
      const endDate = (this.$moment(`${this.year}-${this.month}`).endOf('month')).toISOString()
      const filter = `&filter=termContract.id : '${id}' and stemStartDate>:'${startDate}' and stemEndDate<:'${endDate}'`
      return this.$request.get({
        url: `${this.$apis.orders}?pageSize=${9999}&pageNumber=${0}${filter}&sort=updatedAt DESC`
      })
    },
    getContractDates () {
      const { contractEndDate, contractStartDate } = this.termContractData
      this.contractDates.startYear = contractStartDate.split('-')[0] * 1
      this.contractDates.endYear = contractEndDate.split('-')[0] * 1
      this.contractDates.startMonth = contractStartDate.split('-')[1] * 1
      this.contractDates.endMonth = contractEndDate.split('-')[1] * 1
      this.contractDates.startMonth = contractStartDate.split('-')[1] * 1
      this.contractDates.endDay = contractEndDate.split('-')[1] * 1
      this.contractDates.startDay = contractEndDate.split('-')[1] * 1
      const numOfYear = this.contractDates.endYear - this.contractDates.startYear
      const numOfMonth = this.contractDates.endMonth - this.contractDates.startMonth
      this.yearDropDownList = [
        { value: this.contractDates.startYear, label: this.contractDates.startYear }
      ]
      if (numOfYear > 0) {
        for (let i = 1; i <= numOfYear; i++) {
          const year = this.contractDates.startYear
          const element = { value: year + i, label: year + i }
          this.yearDropDownList.push(element)
        }
      } else {
        this.yearDropDownList = [{ value: this.contractDates.startYear, label: this.contractDates.startYear }]
        if ((numOfMonth > 0) && (numOfYear >= 0)) {
          this.monthDropDownList = this.MONTH_OPTION.splice(this.contractDates.startMonth - 1, numOfMonth + 1)
        }
      }
      if (this?.termContractData?.id) {
        this.getChildTableData()
      }
    },
    getChildTableData () {
      this.childTableLoading = true
      this.getOrders(this.termContractData.id).then(res => {
        if (res?.code === 1000) {
          this.totalOrdersNum = res.data.numberOfElements
          this.totalOrdersInMomth = res.data.numberOfElements
          const data = res.data.content
          if (data.length > 0) {
            data.forEach(element => {
              element.etaDiaplayName = `${this.$moment(element.stemStartDate).format('DD-MM-YYYY')} - ${this.$moment(element.stemEndDate).format('DD-MM-YYYY')}`
              return element
            })
          }
          this.childTableData = data
          this.childTableLoading = false
          this.$emit('doLayout')
        }
      })
    },
    handleChangeYear (val) {
      this.year = val
      this.getChildTableData()
    },
    handleChangeMonth (val) {
      this.month = val
      this.getChildTableData()
    },
    // as same as TobleOrder
    tableOrderTypeStyle (status) {
      switch (status) {
        case ORDER_STATUS.PENDING_SELLER_CONFIRMATION:
          return {
            color: '#eac66c'
          }
        case ORDER_STATUS.PENDING_BUYER_CONFIRMATION:
          return {
            color: '#eac66c'
          }
        case ORDER_STATUS.CONFIRMED:
          return {
            color: '#6fcf6d'
          }
        case ORDER_STATUS.PENDING_SELLER_CANCELLATION:
          return {
            color: '#f4aaaa'
          }
        case ORDER_STATUS.PENDING_BUYER_CANCELLATION:
          return {
            color: '#f4aaaa'
          }
        case ORDER_STATUS.PENDING_CREDIT_AUTHORIZATION:
          return {
            color: '#e7ad6f'
          }
        case ORDER_STATUS.CREDIT_AUTHORIZATION_REJECTED:
          return {
            color: '#945e26'
          }
        case ORDER_STATUS.DRAFT:
          return {
            color: '#6995d5'
          }
        default: // CANCELLED
          return {
            color: '#d26868'
          }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../index";
  .el-dropdown-item:hover {
    background-color: #E7EBF0;
    color: #3f5f88;
  }
  /deep/.el-table__row--level-1 td:first-child{
    padding-left: 24px !important;
  }
.el-tag{
  font-size: 11px;
}
</style>
