var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "flex-start" }, [
        _c(
          "div",
          { staticStyle: { "margin-right": "10px", width: "120px" } },
          [
            _c(
              "el-select",
              {
                on: { change: _vm.handleChangeYear },
                model: {
                  value: _vm.year,
                  callback: function ($$v) {
                    _vm.year = $$v
                  },
                  expression: "year",
                },
              },
              _vm._l(_vm.yearDropDownList, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-right": "20px", width: "120px" } },
          [
            _c(
              "el-select",
              {
                on: { change: _vm.handleChangeMonth },
                model: {
                  value: _vm.month,
                  callback: function ($$v) {
                    _vm.month = $$v
                  },
                  expression: "month",
                },
              },
              _vm._l(_vm.monthDropDownList, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c("div", { staticStyle: { "margin-right": "20px" } }, [
          _c("p", { staticClass: "sub-title-text" }, [
            _vm._v("No. of Orders: " + _vm._s(_vm.totalOrdersInMomth)),
          ]),
        ]),
        _vm.totalDeliveredInMomth
          ? _c(
              "div",
              { staticClass: "flex-column" },
              [
                _c("strong", { staticClass: "sub-title-text" }, [
                  _vm._v(" Total Delivered:"),
                ]),
                _vm._l(_vm.totalDeliveredInMomth, function (item) {
                  return _c(
                    "p",
                    { key: item.id, staticClass: "text-left-12" },
                    [
                      _vm._v(
                        _vm._s(item.displayName) +
                          ": " +
                          _vm._s(item.totalQty) +
                          " MT"
                      ),
                    ]
                  )
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.childTableLoading,
              expression: "childTableLoading",
            },
          ],
          attrs: {
            data: _vm.childTableData,
            "cell-style": _vm.CELL_STYLE,
            "header-cell-style": {
              alignSelf: "center",
              textAlign: "center",
              fontSize: "12px",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Status", "min-width": "200px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("p", [
                      _c("em", {
                        staticClass: "iconfont icon-yuandianzhong",
                        style: _vm.tableOrderTypeStyle(
                          scope.row.sellerSysOrganizationId === _vm.myCompanyId
                            ? scope.row.sellerOrderStatus
                            : scope.row.buyerOrderStatus
                        ),
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            scope.row.sellerSysOrganizationId ===
                              _vm.myCompanyId
                              ? _vm.capitalizeFirstLetter(
                                  scope.row.sellerOrderStatus,
                                  "_"
                                )
                              : _vm.capitalizeFirstLetter(
                                  scope.row.buyerOrderStatus,
                                  "_"
                                )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Ref No.",
              "min-width": "150px",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    (scope.row.sellerSysOrganizationId === _vm.myCompanyId &&
                      scope.row.sellerReferenceNo) ||
                    (scope.row.buyerSysOrganizationId === _vm.myCompanyId &&
                      scope.row.buyerReferenceNo)
                      ? _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.sellerSysOrganizationId ===
                                  _vm.myCompanyId
                                  ? scope.row.sellerReferenceNo
                                  : scope.row.buyerReferenceNo
                              ) +
                              " "
                          ),
                        ])
                      : _c("p", [_vm._v(" - ")]),
                  ]
                },
              },
            ]),
          }),
          _vm.termContractData.termContractType ===
          _vm.TERM_CONTRACT_TYPE.EX_WHARF
            ? _c("el-table-column", {
                attrs: {
                  label: "Terminal",
                  "min-width": "150px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("p", [
                            _vm._v(
                              " " + _vm._s(scope.row.vesselName || "-") + " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1221805770
                ),
              })
            : _c("el-table-column", {
                attrs: {
                  label: "Vessel",
                  "min-width": "150px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("p", [
                          _vm._v(
                            " " + _vm._s(scope.row.vesselName || "-") + " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
          _c("el-table-column", {
            attrs: { label: "ETA Range (From-To)", width: "200px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("p", [
                      _vm._v(
                        " " + _vm._s(scope.row.etaDiaplayName || "-") + " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Product/Grade/Specs",
              "min-width": "300px",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.products, function (item) {
                    return _c(
                      "p",
                      { key: item.id },
                      [
                        _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v(_vm._s(`${item.displayName}`)),
                        ]),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Min - Max Qty",
              "min-width": "100px",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.products, function (item) {
                    return _c("div", { key: item.id }, [
                      item.minimumQuantity
                        ? _c("p", [
                            _vm._v(
                              _vm._s(
                                `${item.minimumQuantity} ${item.unitMetric}`
                              ) +
                                " - " +
                                _vm._s(
                                  `${item.maximumQuantity} ${item.unitMetric}`
                                )
                            ),
                          ])
                        : _c("p", [_vm._v("-")]),
                    ])
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Final Qty",
              "min-width": "100px",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.products, function (item) {
                    return _c("div", { key: item.id }, [
                      item.finalQuantity
                        ? _c("p", [
                            _vm._v(
                              _vm._s(`${item.finalQuantity} ${item.unitMetric}`)
                            ),
                          ])
                        : _c("p", [_vm._v("-")]),
                    ])
                  })
                },
              },
            ]),
          }),
          _vm.termContractData.termContractType ===
          _vm.TERM_CONTRACT_TYPE.EX_WHARF
            ? _c("el-table-column", {
                attrs: {
                  label: "Loading Qty",
                  "min-width": "100px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return _vm._l(scope.row.products, function (item) {
                          return _c("div", { key: item.id }, [
                            item.verifiedQuantity
                              ? _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      `${item.verifiedQuantity} ${item.unitMetric}`
                                    )
                                  ),
                                ])
                              : _c("p", [_vm._v("-")]),
                          ])
                        })
                      },
                    },
                  ],
                  null,
                  false,
                  2689965795
                ),
              })
            : _c("el-table-column", {
                attrs: {
                  label: "Delivered Qty",
                  "min-width": "100px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.products, function (item) {
                        return _c("div", { key: item.id }, [
                          item.verifiedQuantity
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(
                                    `${item.verifiedQuantity} ${item.unitMetric}`
                                  )
                                ),
                              ])
                            : _c("p", [_vm._v("-")]),
                        ])
                      })
                    },
                  },
                ]),
              }),
          _vm.termContractData.termContractType !==
          _vm.TERM_CONTRACT_TYPE.EX_WHARF
            ? _c("el-table-column", {
                attrs: {
                  label: "BDN",
                  "min-width": "150px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return _vm._l(scope.row.products, function (item) {
                          return _c("div", { key: item.id }, [
                            item.physicalSupplierName
                              ? _c("p", [
                                  _vm._v(_vm._s(item.physicalSupplierName)),
                                ])
                              : _c("p", [_vm._v("-")]),
                          ])
                        })
                      },
                    },
                  ],
                  null,
                  false,
                  1347886714
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }